var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Navbar', {
    attrs: {
      "nav-light": true
    }
  }), _c('section', {
    staticClass: "bg-half bg-primary d-table w-100"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-lg-12 text-center"
  }, [_c('div', {
    staticClass: "page-next-level"
  }, [_c('h4', {
    staticClass: "title text-white",
    staticStyle: {
      "text-transform": "capitalize"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("reporting.annual")) + " ")])])])])])]), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container card public-profile border-0 rounded shadow overflow-hidden"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "text-center uppercase"
  }, [_vm._v(_vm._s(_vm.$t("reporting.awaris-detailed-yearly")))]), _c('div', {
    staticClass: "row mt-5"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-4 col-form-label",
    attrs: {
      "for": "staticEmail"
    }
  }, [_vm._v(_vm._s(_vm.$t("year")))]), _c('div', {
    staticClass: "col-sm-8 col-md-8 col-lg-6"
  }, [_c('div', {
    staticClass: "form custom-form"
  }, [_c('div', {
    staticClass: "form-group mb-0"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.currentYear,
      expression: "currentYear"
    }],
    staticClass: "form-control custom-select",
    attrs: {
      "id": "Sortbylist-job"
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.currentYear = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }, _vm.yearChange]
    }
  }, [_c('option', {
    attrs: {
      "value": "2023"
    }
  }, [_vm._v("2023")]), _c('option', {
    attrs: {
      "value": "2022"
    }
  }, [_vm._v("2022")]), _c('option', {
    attrs: {
      "value": "2021"
    }
  }, [_vm._v("2021")]), _c('option', {
    attrs: {
      "value": "2020"
    }
  }, [_vm._v("2020")]), _c('option', {
    attrs: {
      "value": "2019"
    }
  }, [_vm._v("2019")]), _c('option', {
    attrs: {
      "value": "2018"
    }
  }, [_vm._v("2018")])])])])])])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-4 col-form-label",
    attrs: {
      "for": "staticEmail"
    }
  }, [_vm._v(_vm._s(_vm.$t("organization")))]), _c('div', {
    staticClass: "col-sm-8 col-md-8 col-lg-7"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.selectedOrganization,
      expression: "selectedOrganization"
    }],
    staticClass: "form-control",
    attrs: {
      "disabled": _vm.userRole != 'Staff'
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.selectedOrganization = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }, _vm.onOrganizationChange]
    }
  }, [_c('option', {
    attrs: {
      "value": "null"
    }
  }, [_vm._v("-- " + _vm._s(_vm.$t("choose-org")) + " --")]), _c('hr'), _c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("all-org")))]), _vm._l(Object.entries(this.organizations), function (type, index) {
    return _c('optgroup', {
      key: index,
      attrs: {
        "label": type[0]
      }
    }, _vm._l(type[1], function (organization, index2) {
      return _c('option', {
        key: index2,
        domProps: {
          "value": organization.id
        }
      }, [_vm._v(" " + _vm._s(organization.name) + " ")]);
    }), 0);
  })], 2)])])]), _vm.selectedOrganization != 'null' ? _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-4 col-form-label",
    attrs: {
      "for": "staticEmail"
    }
  }, [_vm._v(_vm._s(_vm.$t("org.nav2")))]), _c('div', {
    staticClass: "col-sm-8 col-md-8 col-lg-6"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.selectedBranch,
      expression: "selectedBranch"
    }],
    staticClass: "form-control",
    attrs: {
      "disabled": _vm.userRole == 'Admin'
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.selectedBranch = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }, _vm.getWasiats]
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("all-branch")))]), _vm._l(_vm.branches, function (branch, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": branch.id
      }
    }, [_vm._v(" " + _vm._s(branch.branch_name) + " ")]);
  })], 2)])])]) : _vm._e()]), _c('div', {
    staticClass: "row mt-4"
  }, [_c('div', {
    staticClass: "col-md-12 text-right mb-4"
  }, [_c('b-button', {
    staticClass: "btn btn-secondary btn-sm",
    on: {
      "click": _vm.downloadExcel
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("download-excel")))])], 1)]), _c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table",
    domProps: {
      "innerHTML": _vm._s(_vm.html_table)
    }
  })])])])]), _c('Footer'), _c('a', {
    directives: [{
      name: "scroll-to",
      rawName: "v-scroll-to",
      value: '#topnav',
      expression: "'#topnav'"
    }],
    staticClass: "btn btn-icon btn-soft-primary back-to-top",
    attrs: {
      "href": "javascript: void(0);",
      "id": "back-to-top"
    }
  }, [_c('arrow-up-icon', {
    staticClass: "icons"
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }